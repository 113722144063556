import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
}))

const WillsAndProbate = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Family Law" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Wills, Probate and Letters of Administration, Enduring
              Guardianship and Powers of Attorney
            </Typography>
            <Typography component="p" paragraph>
              Our office can prepare for you:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">Wills</Typography>
              <Typography component="li">Probate</Typography>
              <Typography component="li">Letters of Administration</Typography>
              <Typography component="li">Enduring guardianship</Typography>
              <Typography component="li">Enduring power of attorney</Typography>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              1. Why have a Will?
            </Typography>
            <Typography component="p" paragraph>
              Wills should be done as a matter of course whenever someone
              acquires assets, property, money, shares, etc.
            </Typography>
            <Typography component="p" paragraph>
              With a Will you can determine exactly how you want your estate to
              be distributed, provide securities for children by nominating a
              guardian for your infant children, and also create a discretionary
              trust so that your estate can be managed while your children are
              young. You can amend your Will at any time.
            </Typography>
            <Typography component="p" paragraph>
              A Will generally has several components:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                The details of the person making the Will.
              </Typography>
              <Typography component="li">
                The appointment of executor/executrix to administer the estate.
              </Typography>
              <Typography component="li">
                The appointment of a guardian to care for infant children.
              </Typography>
              <Typography component="li">
                The appointment of a Trustee to manage the estate if the
                children are aged under 18 years.
              </Typography>
              <Typography component="li">
                Instructions to the Trustee as to the management of the estate
                where the Will provides for a Trust (discretionary trust) for
                the estate to be distributed in part or in full to the children
                of the deceased under the age of 18 years.
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              The role of the executor/executrix is to organise your burial, pay
              all your debts and liabilities and distribute your estate to your
              nominated beneficiaries.
            </Typography>

            <Typography component="p" paragraph>
              Your Will covers all your assets except for your superannuation
              fund. You will need to provide a binding death nomination to your
              super fund and update this regularly or alternatively provide a
              clause in your Will for your superannuation funds to be given to
              your personal representative of your Will and distributed
              according to your Will.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              2. When would an executor/executrix apply for Probate?
            </Typography>

            <Typography component="p" paragraph>
              Where the assets are a sizeable amount, or where there is property
              involved, the executor/executrix would go to Court to apply for
              Probate. The Court would certify that the Will is valid, and
              authorise the executor/executrix to pay your debts and distribute
              your estate. The Court document called Probate would be then used
              by the executor/executrix to transfer the property and distribute
              the estate according to the terms of the Will.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              3. What if there is no Will?
            </Typography>
            <Typography component="p" paragraph>
              Your estate would be considered to be “intestate”. In such case, a
              beneficiary, generally your spouse, would apply to the Court for
              Letters of Administration i.e. to be nominated as Administrator of
              your Will.
            </Typography>

            <Typography component="p" paragraph>
              The Succession Act 2006 (NSW) sets out the order in which eligible
              relatives will inherit the estate of the deceased – known as
              intestacy rules. These rules do not allow for any flexibility as
              to how your assets are to be distributed.
            </Typography>

            <Typography component="p" paragraph>
              Where there are no children, then your spouse would get your whole
              estate. However, where there are children from your marriage or
              previous relationships, then your assets would be distributed
              differently and your spouse would only get a part of your assets.
              Where there is no spouse or children, then your parents inherit,
              and where no parents then your siblings etc.{" "}
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              4. When to appoint an enduring power of attorney and enduring
              guardian
            </Typography>

            <Typography component="p" paragraph>
              If you are getting older or having health problems, two other
              matters that you should consider are:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                appointing an enduring guardian
              </Typography>
              <Typography component="li">
                appointing an enduring power of attorney.
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              An enduring guardian is a person that you trust and is happy to
              accept the responsibility to make decisions about your health
              care, welfare and accommodation issues. This is relevant where you
              have health issues or you have deceasing memory. The enduring
              guardian can consult with doctors to decide the health care you
              require, decide whether you need to have nursing home
              accommodation, and can help organise your life to be more
              comfortable when you are not well.
            </Typography>

            <Typography component="p" paragraph>
              An enduring power of attorney is about the management of your
              financial assets. The enduring power of attorney allows the
              attorney to make decisions about your assets and money when you
              are well or in poor health and not well. You can give the attorney
              unlimited powers to act on your behalf in your financial matters
              or you can limit the power to specific matters only.
            </Typography>
            <Typography component="p" paragraph>
              In both cases, you decide who your enduring guardian and power of
              attorney are to be. You can limit these powers to apply when a
              doctor certifies that you are no longer able to manage your health
              decisions and financial decisions. These powers can be revoked and
              changed by you.
            </Typography>
            <Typography component="p" paragraph>
              If you are getting older, you may wish to organise all three
              documents together: your Will, enduring guardianship and enduring
              power of attorney.
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default WillsAndProbate
